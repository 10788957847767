import { useState, useEffect } from "react"

export const useFilterCheckbox = (filters, setFilters, variant) => {
  const [checkedState, setCheckedState] = useState([])

  const handleOnChange = (id) => {
    const currentIndex = checkedState.indexOf(id)
    const newCheckedState = [...checkedState]
    if (currentIndex === -1) {
      newCheckedState.push(id)
    } else {
      newCheckedState.splice(currentIndex, 1)
    }

    setCheckedState(newCheckedState)
    const newFilters = { ...filters }
    newFilters[variant] = newCheckedState
    setFilters(newFilters)
  }

  const updateCheckedValue = (id) => {
    const isChecked = filters[variant].indexOf(id) === -1 ? false : true
    return isChecked
  }

  useEffect(() => {}, [filters])

  return { handleOnChange, updateCheckedValue }
}
