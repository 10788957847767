import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { Container } from "./styledComponents"
import ArticleElement from "./articleElement"
import ValuesFilter from "./valuesFilter"
import SearchBar from "./searchBar"
import { useArticlesFilters } from "./hooks/useArticlesFilters"

const ArticlesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 68px 0;
  @media (max-width: 1195px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 840px) {
    grid-template-columns: 1fr;
  }
`

const LoadMoreButton = styled.button`
  display: inline-block;
  position: relative;
  white-space: nowrap;
  font-family: "Montserrat";
  font-size: ${(props) =>
    ["small", "medium"].includes(props.size) ? "1rem" : "1.125rem"};
  padding: ${(props) =>
    props.size === "small"
      ? "0.625rem 3rem"
      : props.size === "medium"
      ? "0.75rem 2.5rem"
      : "0.9375rem 3rem"};
  /* small medium big */
  border: none;
  color: #fff;
  font-weight: bold;
  background: ${(props) => props.theme.secondary};
  box-shadow: 0px 3px 6px #00000029;
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.secondary};
  transition: color 0.1s ease-in-out, background 0.2s ease-in-out;
  :focus {
    border-style: dashed;
    background: #fff;
    color: ${(props) => props.theme.secondary};
    outline: none;
  }
  :hover {
    color: ${(props) => props.theme.secondary};
    background: #fff;
  }
`
const FilterSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 3rem;
  @media screen and (max-width: 991px) {
    justify-content: space-between;
    flex-basis: 50%;
    padding: 0;
  }
`

const Button = styled.button`
  display: inline-block;
  min-width: 142px;
  margin-left: 8px;
  position: relative;
  text-align: center;
  line-height: 1.25;
  width: fit-content;
  font-family: "Montserrat";
  font-size: 1rem;
  padding: 0.3rem 0.7rem;
  border: none;
  color: #fff;
  font-weight: bold;
  background: ${(props) => props.theme.secondary};
  box-shadow: 0px 3px 6px #00000029;
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.secondary};
  transition: color 0.1s ease-in-out, background 0.2s ease-in-out;

  :hover {
    color: ${(props) => props.theme.secondary};
    background: #fff;
  }

  @media screen and (max-width: 576px) {
    margin: 1rem 0 0;
  }
`

const ArticlesWithCategoriesGrid = ({
  articles,
  filterButtonText,
  categoriesFilterText,
  tagsFilterText,
  clearFiltersButtonText,
}) => {
  const { wordpressPage } = useStaticQuery(graphql`
    query {
      wordpressPage(
        template: { eq: "page-templates/page-global-settings.php" }
      ) {
        cmb2 {
          global_settings {
            loadMoreArticles
          }
        }
      }
    }
  `)
  const {
    tags,
    filters,
    categories,
    setFilters,
    handleFilters,
    filteredArticles,
    stripedFilteredArticles,
    numberOfArticles,
    setNumberOfArticles,
    expandedFilter,
    toggleExpand,
    searchedValue,
    handleSearch,
    searchBarPlaceholder,
    handleClearFilters,
  } = useArticlesFilters(articles)

  return (
    <Container>
      <FilterSection>
        <ValuesFilter
          variant={"tags"}
          checkboxes={tags}
          filterText={tagsFilterText}
          buttonText={filterButtonText}
          handleFilters={handleFilters}
          filters={filters}
          setFilters={setFilters}
          expandedFilter={expandedFilter}
          toggleExpand={toggleExpand}
        />
        <ValuesFilter
          variant="categories"
          checkboxes={categories}
          filterText={categoriesFilterText}
          buttonText={filterButtonText}
          handleFilters={handleFilters}
          filters={filters}
          setFilters={setFilters}
          expandedFilter={expandedFilter}
          toggleExpand={toggleExpand}
        />
        <Button onClick={handleClearFilters}>{clearFiltersButtonText}</Button>
        <SearchBar
          placeholder={searchBarPlaceholder}
          searchedValue={searchedValue}
          handleSearch={handleSearch}
        />
      </FilterSection>
      <ArticlesGrid>
        {stripedFilteredArticles.map(
          ({ title, excerpt, featured_media, path }) => (
            <div key={title}>
              <ArticleElement
                style={{ margin: "10px auto" }}
                title={title}
                excerpt={excerpt}
                localFile={featured_media.localFile}
                path={path}
              />
            </div>
          )
        )}
      </ArticlesGrid>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {filteredArticles.length >= numberOfArticles ? (
          <LoadMoreButton
            type="button"
            onClick={() => setNumberOfArticles(numberOfArticles + 6)}
          >
            {wordpressPage.cmb2.global_settings.loadMoreArticles}
          </LoadMoreButton>
        ) : null}
      </div>
    </Container>
  )
}

export default ArticlesWithCategoriesGrid
