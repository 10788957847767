import React from "react"
import styled, { css } from "styled-components"
import { useFilterCheckbox } from "./hooks/useFilterCheckbox"

const Wrapper = styled.div`
  position: relative;
  min-width: 172px;
  @media (max-width: 920px) {
    min-width: 124px;
  }
  @media screen and (max-width: 767px) {
    flex-basis: 30%;
    min-width: auto;
  }
  @media screen and (max-width: 576px) {
    flex-basis: 50%;
  }
`
const TextWrapper = styled.div`
  transition: all 0.3s ease;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  > span {
    font-size: 18px;
    line-height: 30px;
    font-weight: bold;
    color: inherit;
  }

  > i {
    line-height: 30px;
    color: inherit;
    transition: transform 0.3s ease;
    transform: ${(props) =>
      props.expandedFilter === props.variant ? "rotate(-180deg)" : "rotate(0)"};
  }

  :hover {
    color: ${(props) => props.theme.secondary};
  }
`
const ContentWrapper = styled.div`
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  z-index: 1;
  min-width: 300px;
  padding: 1rem;
  box-shadow: 0px 3px 6px #00000029;
  background-color: #fff;
  transform: ${(props) =>
    props.expandedFilter === props.variant ? "scaleY(1)" : "scaleY(0)"};
  transition: all 0.3s ease;
  transform-origin: top;
  ${(props) =>
    props.variant === "categories" &&
    css`
      @media screen and (max-width: 767px) {
        left: initial;
        right: 0;
      }
    `}
`
const Button = styled.button`
  display: inline-block;
  min-width: 181px;
  position: relative;
  text-align: center;
  line-height: 1.25;
  width: fit-content;
  font-family: "Montserrat";
  font-size: 1rem;
  padding: 0.3rem 0.7rem;
  border: none;
  color: #fff;
  font-weight: bold;
  background: ${(props) => props.theme.secondary};
  box-shadow: 0px 3px 6px #00000029;
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.secondary};
  transition: color 0.1s ease-in-out, background 0.2s ease-in-out;

  :hover {
    color: ${(props) => props.theme.secondary};
    background: #fff;
  }
`

const CheckboxesWrapper = styled.div`
  margin-bottom: 1rem;
`

const CheckboxWrapper = styled.div`
  display: flex;
  margin-bottom: 1rem;

  > input {
    display: inline-block;
    width: 0.6rem;
    opacity: 0;
  }
  > label {
    position: relative;
    margin-left: 16px;
    font-size: 18px;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;

    :hover {
      color: ${(props) => props.theme.secondary};
    }
    ::before {
      content: "";
      position: absolute;
      top: 50%;
      left: -1.2rem;
      z-index: 1;
      transform: translateY(-50%);
      width: 0.6rem;
      height: 0.6rem;
      outline: 1px solid ${(props) => props.theme.secondary};
    }
  }
  > input:checked + label::before {
    background-color: ${(props) => props.theme.secondary};
  }
`

const ValuesFilter = ({
  variant,
  checkboxes,
  filterText,
  buttonText,
  handleFilters,
  filters,
  setFilters,
  expandedFilter,
  toggleExpand,
}) => {
  const { handleOnChange, updateCheckedValue } = useFilterCheckbox(
    filters,
    setFilters,
    variant
  )
  return (
    <Wrapper>
      <TextWrapper
        onClick={() => toggleExpand(variant)}
        expandedFilter={expandedFilter}
        variant={variant}
      >
        <span>{filterText}&nbsp;</span>
        <i className="fa-solid fa-angle-down"></i>
      </TextWrapper>
      <ContentWrapper expandedFilter={expandedFilter} variant={variant}>
        <CheckboxesWrapper>
          {checkboxes.map(({ name, wordpress_id }, index) => (
            <CheckboxWrapper key={wordpress_id}>
              <input
                type="checkbox"
                id={`custom-checkbox-${wordpress_id}`}
                name={name}
                value={name}
                checked={updateCheckedValue(wordpress_id)}
                onChange={() => handleOnChange(wordpress_id)}
              />
              <label htmlFor={`custom-checkbox-${wordpress_id}`}>{name}</label>
            </CheckboxWrapper>
          ))}
        </CheckboxesWrapper>
        <Button size="small" onClick={handleFilters}>
          {buttonText}
        </Button>
      </ContentWrapper>
    </Wrapper>
  )
}

export default ValuesFilter
