import React from "react"
import ArrowMore from "../components/arrowMore"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import styled from "styled-components"
import { Container } from "../components/styledComponents"
import { BigMobileFont } from "../components/typography"
import LastCTASection from "../components/lastCTASection"
import ArticlesWithCategoriesGrid from "../components/articlesWithCategories"
import FlowMapImage from "../components/flowMapImage"
import Popup from "../components/popup"

const HeroSection = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  min-height: calc(100vh - 180px);
  margin-bottom: 60px;
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
  }
`

const HeroImageWrapper = styled.div`
  margin: 0 40px 0 0;
  width: 30%;
  flex-shrink: 0;
  @media (max-width: 768px) {
    width: 60%;
    margin: 0 0 1rem 0;
  }
`

const Heading = styled.h1`
  margin-bottom: 10px;
`

const Paragraph = styled.p`
  max-width: 682px;
  font-size: 18px;
`

const Blog = ({ data }) => {
  const {
    lastCTASectionTitle,
    lastCTASectionText,
    lastCTASectionButtonUrl,
    lastCTASectionButtonText,
    firstSectionTitle,
    firstSectionText,
    firstSectionImage,
    categoriesFilterText,
    tagsFilterText,
    filterButtonText,
    clearFiltersButtonText,
    searchBarPlaceholder,
  } = data.wordpressPage.cmb2.metabox_blog

  return (
    <Layout>
      <SEO
        meta={data.wordpressPage.yoast_meta}
        title={data.wordpressPage.title}
        path="/blog"
      />
      <Container>
        <HeroSection>
          <HeroImageWrapper>
            <FlowMapImage
              imagePublicURL={firstSectionImage.localFile.publicURL}
              size={{ width: 199, height: 199 }}
            />
          </HeroImageWrapper>
          <div style={{ marginBottom: "40px" }}>
            <Heading>
              <BigMobileFont desktopFontSize="3.5rem">
                {firstSectionTitle}
              </BigMobileFont>
            </Heading>
            <Paragraph>{firstSectionText}</Paragraph>
            <ArrowMore />
          </div>
        </HeroSection>
        <ArticlesWithCategoriesGrid
          articles={data.allWordpressPost.nodes}
          filterButtonText={filterButtonText}
          categoriesFilterText={categoriesFilterText}
          tagsFilterText={tagsFilterText}
          searchBarPlaceholder={searchBarPlaceholder}
          clearFiltersButtonText={clearFiltersButtonText}
        />
      </Container>
      <LastCTASection
        title={lastCTASectionTitle}
        text={lastCTASectionText}
        buttonText={lastCTASectionButtonText}
        buttonUrl={lastCTASectionButtonUrl}
      />
      <Popup />
    </Layout>
  )
}

export const query = graphql`
  query BlogPageQuery {
    wordpressPage(template: { eq: "page-templates/page-blog.php" }) {
      title
      yoast_meta {
        content {
          source_url
        }
        normalized_content
        name
        property
      }
      cmb2 {
        metabox_blog {
          lastCTASectionTitle
          lastCTASectionText
          lastCTASectionButtonUrl
          lastCTASectionButtonText
          firstSectionTitle
          firstSectionText
          categoriesFilterText
          tagsFilterText
          filterButtonText
          clearFiltersButtonText
          searchBarPlaceholder
          firstSectionImage {
            localFile {
              publicURL
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    allWordpressPost(sort: { fields: date, order: DESC }) {
      nodes {
        title
        excerpt
        path
        featured_media {
          localFile {
            childImageSharp {
              fixed(height: 215, width: 330) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
        categories {
          name
          wordpress_id
        }
        tags {
          name
          wordpress_id
        }
      }
    }
  }
`

export default Blog
