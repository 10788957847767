import { useState } from "react"
import { getUniqueValues } from "../../utils/getUniqueValues"

export const useArticlesFilters = (articles) => {
  const tags = getUniqueValues(articles, "tags", "wordpress_id")
  const categories = getUniqueValues(articles, "categories", "wordpress_id")
  const [filters, setFilters] = useState({
    tags: [],
    categories: [],
  })
  const [filteredArticles, setFilteredArticles] = useState(articles)
  const [numberOfArticles, setNumberOfArticles] = useState(6)
  const [expandedFilter, setExpandedFilter] = useState("")
  const [searchedValue, setSearchedValue] = useState("")

  const toggleExpand = (variant) => {
    setExpandedFilter((prev) => (prev !== variant ? variant : ""))
  }

  const filterArticles = (articles, key) => {
    return articles.filter((article) => {
      // Check if article contain filtered tags or categories
      if (!article[key]) return false
      return article[key].some((element) =>
        filters[key].includes(element.wordpress_id)
      )
    })
  }

  const handleFilters = () => {
    setFilteredArticles(articles)
    if (filters.tags.length < 1 && filters.categories.length < 1) return

    const articleFilteredByTags = filterArticles(articles, "tags")

    const articleFilteredByCategories = filterArticles(articles, "categories")

    const newArticles = Array.from(
      new Set(articleFilteredByTags.concat(articleFilteredByCategories))
    )

    setFilteredArticles(newArticles)
  }

  const filterArticlesBySearchedValue = (typedValue) => {
    if (typedValue.length === 0) return setFilteredArticles(articles)
    const searchedArticles = articles.filter((article) => {
      if (!article.title) return false

      return article.title.toLowerCase().includes(typedValue.toLowerCase())
    })
    setFilteredArticles(searchedArticles)
  }

  const handleSearch = (event) => {
    const typedValue = event.target.value
    setSearchedValue(typedValue)
    filterArticlesBySearchedValue(typedValue)
  }

  const handleClearFilters = () => {
    setExpandedFilter("")
    setFilteredArticles(articles)

    const resetedFilters = {
      tags: [],
      categories: [],
    }
    setFilters(resetedFilters)
  }

  const stripedFilteredArticles = filteredArticles.slice(0, numberOfArticles)

  return {
    tags,
    categories,
    filters,
    setFilters,
    handleFilters,
    filteredArticles,
    stripedFilteredArticles,
    numberOfArticles,
    setNumberOfArticles,
    expandedFilter,
    toggleExpand,
    searchedValue,
    handleSearch,
    handleClearFilters,
  }
}
